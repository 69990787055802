<template>

  <!--  -->
  <div class="layout">
    <div class="container py-5">
      <div class="container-inner mt-5 mb-0 mb-md-5">
        <div class="row d-flex justify-content-between py-5">
          <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mt-5 mt-lg-0">
            <form class="login-form px-4">
              <h2 class="text-center mb-5">Sign In</h2>
              <div class="form-group">
                <label>Email address</label>
                <input
                :autofocus="true"
                  v-model="form.email"
                  class="customForm"
                  type="text"
                  name="username"
                  placeholder="Enter your email"
                  autocomplete="email"
                />
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  @keypress="passKeyPress($event)"
                  v-model="form.password"
                  class="customForm"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  autocomplete="new-password"
                />
              </div>
              <div class="form-group mt-4 pt-2">
                <button @click="login" type="button" class="w-100 button">
                  Sign In
                </button>
              </div>
              <div v-if="errors?.length" class="errors-wrapper">
                  <p :key="error" v-for="error of errors" class="error-message">
                      {{error}}
                  </p>
              </div>
            </form>
            <div class="d-flex mx-4 login-links justify-content-between">
              <router-link to="/reset-password">Forgot password?</router-link>
              <router-link to="/register">Don't have an account yet?</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { defineComponent } from "vue";
import Footer from "../../components/Footer.vue";
import config from '../../config';
import spinner from '../../assets/images/spinner.svg';

export default defineComponent({
  name: "Login",
  data: function () {
    return {
      form: {
        email: "",
        password: "",
      },
      accessToken: '',
      errors: [],
      loading: true,
      spinner: spinner
    };
  },
  components: {
    //   Header,
  },
  mounted() {
    console.log(config);
    this.$store.watch(state => {
        this.accessToken = state.accessToken;
        this.loading = state.globalLoading;
    });
    // this.checkAuth();
  },
  methods: {
        passKeyPress(e) {
            if (e.key === 'Enter') {
                this.login();
            }
        },
      login() {
          this.errors = [];
          fetch(`${config.API}/api/v1/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: this.form.email,
                    password: this.form.password
                })
          })
          .then(res => res.json())
          .then(data => {
              console.log('login data: ', data);
              if (data.errors) {
                this.errors = data.errors;
                return;
              }
              this.$store.dispatch('setUserData', data.data.user);
              this.$store.dispatch('setAccessToken', data.data.access_token);
              this.$store.dispatch('setLoggedIn', true);
              window.localStorage.setItem('accessToken', data.data.access_token);
              fetch(`${config.API}/api/v1/has-minimum-deposited`, {
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  Authorization: `Bearer ${data.data.access_token}`
                }
              })
              .then(res => res.json())
              .then(async data => {
                await this.$store.dispatch('setHasMinDeposit', data.data.has_minimum_deposited_funds);
                this.$router.push('/');
              })
              .catch(error => console.log(error));
              // data.data.access_token
          })
          .catch(error => {
              console.log(error);
          })
      }
  }
});
</script>
